export default {
  state: () => ({
    wallet: {},
  }),
  mutations: {
    SET_WALLET(state, wallet) {
      state.wallet = wallet;
    },
  },
  actions: {
    setWallet(context, basket) {
      context.commit('SET_WALLET', basket);
    },
  }
};