import { createApp, provide, h } from 'vue';

import { DefaultApolloClient } from '@vue/apollo-composable';
import { apolloClient } from './apollo-client';

import App from './App.vue';
import router from './router';

import store from './store';

import VueCookies from 'vue-cookies';
import vuetify from './plugins/vuetify';

import SwiperClass, { Navigation, Thumbs, Pagination, Grid } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';

import VCalendar from 'v-calendar';
import { loadFonts } from './plugins/webfontloader';

import { createMetaManager } from 'vue-meta';

import { createI18n } from 'vue-i18n';
import ruTranslate from './translate/ru';
import enTranslate from './translate/en';

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'ru',
  messages: {
    en: enTranslate,
    ru: ruTranslate
  }
});

// import swiper module styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

SwiperClass.use([Navigation, Thumbs, Swiper, SwiperSlide, Pagination, Navigation, Grid]);

loadFonts();

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App),
});

app.use(router)
  .use(store)
  .use(VueCookies, { expires: '7d' })
  .use(vuetify)
  .use(VueAwesomeSwiper)
  .use(VCalendar, {})
  .use(i18n)
  .use(createMetaManager())
  .mount('#app');
