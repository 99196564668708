import Vuex from 'vuex';

import hotel from './modules/hotel';
import personal from './modules/personal';
import basket from './modules/basket';
import balance from './modules/balance';
import searchHotel from './modules/search-hotel';

const store = new Vuex.Store({});

store.registerModule("personal", personal);
store.registerModule("hotel", hotel);
store.registerModule("basket", basket);
store.registerModule("balance", balance);
store.registerModule("searchHotel", searchHotel);

export default store;
