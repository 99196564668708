export default {
  state: () => ({
    id: '',
    name: '',
    code: '',
    address: '',
    images: [{}],
    simpleCode: 0,
    description: '',
    city: '',
    country: {},
    facilities: [{}],
    postalCode: "",
    coordinates: {
      longitude: 0,
      latitude: 0
    },
    reviews: [{}],
    rooms: [{}]
  }),
  mutations: {
    SET_ID(state, id) {
      state.id = id;
    },
    SET_NAME(state, name) {
      state.name = name;
    },
    SET_CODE(state, code) {
      state.code = code;
    },
    SET_ADDRESS(state, address) {
      state.address = address;
    },
    SET_IMAGES(state, images) {
      state.images = images;
    },
    SET_SIMPLE_CODE(state, simpleCode) {
      state.simpleCode = simpleCode;
    },
    SET_DESCRIPTION(state, description) {
      state.description = description;
    },
    SET_CITY(state, city) {
      state.city = city;
    },
    SET_COUNTRY(state, country) {
      state.country = country;
    },
    SET_FACILITIES(state, facilities) {
      state.facilities = facilities;
    },
    SET_POSTAL_CODE(state, postalCode) {
      state.postalCode = postalCode;
    },
    SET_COORDINATES(state, coordinates) {
      state.coordinates = coordinates;
    },
    SET_REVIEWS(state, reviews) {
      state.reviews = reviews;
    },
    SET_ROOMS(state, rooms) {
      state.rooms = rooms;
    },
  },
  actions: {
    setId(context, id) {
      context.commit('SET_ID', id);
    },
    setName(context, name) {
      context.commit('SET_NAME', name);
    },
    setCode(context, code) {
      context.commit('SET_CODE', code);
    },
    setAddress(context, address) {
      context.commit('SET_ADDRESS', address);
    },
    setImages(context, images) {
      context.commit('SET_IMAGES', images);
    },
    setSimpleCode(context, simpleCode) {
      context.commit('SET_SIMPLE_CODE', simpleCode);
    },
    setDescription(context, description) {
      context.commit('SET_DESCRIPTION', description);
    },
    setCity(context, city) {
      context.commit('SET_CITY', city);
    },
    setCountry(context, country) {
      context.commit('SET_COUNTRY', country);
    },
    setFacilities(context, facilities) {
      context.commit('SET_FACILITIES', facilities);
    },
    setPostalCode(context, postalCode) {
      context.commit('SET_POSTAL_CODE', postalCode);
    },
    setCoordinates(context, coordinates) {
      context.commit('SET_COORDINATES', coordinates);
    },
    setReviews(context, reviews) {
      context.commit('SET_REVIEWS', reviews);
    },
    setRooms(context, rooms) {
      context.commit('SET_ROOMS', rooms);
    },
  }
};