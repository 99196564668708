export default {
  state: () => ({
    authCompleted: false,
    id: 0,
    name: '',
    email: '',
  }),
  mutations: {
    SET_AUTHORIZATION_USER(state, authCompleted) {
      state.authCompleted = authCompleted;
    },
    SET_AUTHORIZATION_USER_ID(state, id) {
      state.id = id;
    },
    SET_AUTHORIZATION_USER_NAME(state, name) {
      state.name = name;
    },
    SET_AUTHORIZATION_USER_EMAIL(state, email) {
      state.email = email;
    },
  },
  actions: {
    setAuthorizationUser(context, authCompleted) {
      context.commit('SET_AUTHORIZATION_USER', authCompleted);
    },
    setAuthorizationUserId(context, id) {
      context.commit('SET_AUTHORIZATION_USER_ID', id);
    },
    setAuthorizationUserName(context, name) {
      context.commit('SET_AUTHORIZATION_USER_NAME', name);
    },
    setAuthorizationUserEmail(context, email) {
      context.commit('SET_AUTHORIZATION_USER_EMAIL', email);
    },
  }
};