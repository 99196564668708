export default {
  state: () => ({
    basket: [],
    updateBasket: false,
    numberOrders: 0
  }),
  mutations: {
    SET_BASKET(state, basket) {
      state.basket = basket;
    },
    SET_UPDATE_BASKET(state, updateBasket) {
      state.updateBasket = updateBasket;
    },
    SET_NUMBERS_ORDER(state, numberOrders) {
      state.numberOrders = numberOrders;
    },
  },
  actions: {
    setBasket(context, basket) {
      context.commit('SET_BASKET', basket);
    },
    setUpdateBasket(context, updateBasket) {
      context.commit('SET_UPDATE_BASKET', updateBasket);
    },
    setNumbersOrder(context, numberOrders) {
      context.commit('SET_NUMBERS_ORDER', numberOrders);
    },
  }
};