export default {
  state: () => ({
    searchHotel: [],
    addressHotel: {
      name: "",
      latitude: "",
      longitude: ""
    }
  }),
  mutations: {
    SET_SEARCH_HOTEL(state, searchHotel) {
      state.searchHotel = searchHotel;
    },
    SET_ADDRESS_HOTEL(state, addressHotel) {
      state.addressHotel = addressHotel;
    },
  },
  actions: {
    setSearchHotel(context, searchHotel) {
      context.commit('SET_SEARCH_HOTEL', searchHotel);
    },
    setAddressHotel(context, addressHotel) {
      context.commit('SET_ADDRESS_HOTEL', addressHotel);
    },
  }
};